.share-page-wrapper {
    position: relative;
    overflow: hidden;
}

.share-container {
    display: inline-block;
    padding: 1rem;
    border: solid gainsboro 4px;
    width: 90%;
    margin: 1rem 5%;
    border-radius: 8px;
    background-color: rgba(255, 253, 250, 0.9);
}

.share-h1 {
    text-align: center;
    white-space: pre-wrap;
    margin: 0 0 2rem 0;
    animation: 1s cubic-bezier(1, 1.61, 0.62, 0.02) 0s 1 fadeIn;
}

.share-h1:hover {
    background: linear-gradient(90deg, var(--logo-red), var(--logo-blue));
    text-shadow: none;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.FB-IG-Wrapper {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    margin: 3%;
}

.fb-iframe-container {
    min-width: 40%;
    max-width: 500px;
    margin: auto;
}

.ig-iframe-container {
    width: 50%;
    display: flex;
    justify-content: center;
}

.fb-iframe {
    width: 100%;
    height: 700px;
    border: none;
    overflow: hidden;
}

.share-container p {
    margin-top: 0.5rem;
    font-weight: 500;
    animation: 0.8s cubic-bezier(1, 1.22, 0.62, 0.02) 0s 1 fadeIn;
}

@media screen and (max-width: 1300px) {
    .fb-iframe-container {
        min-width: 95%;
    }

    .ig-iframe-container {
        min-width: 95%;
    }

    .FB-IG-Wrapper {
        flex-direction: column;
        align-items: center;
    }
}
