.homepage-info-container {
  display: inline-block;
  padding: 2rem 1rem;
  border: solid gainsboro 4px;
  width: 90%;
  margin: 1rem 5%;
  border-radius: 8px;
  background-color: #fffdfaf5;
  font-size: calc(0.2 * 1vw + 1rem);
}

.h1-wrapper {
  color: white;
  border-radius: 0 0 60px 60px;
  position: relative;
  height: 300px;
}

.homepage-h1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  white-space: pre-wrap;
  margin: 1.5rem 1rem 2rem 1rem;
  background: linear-gradient(90deg, rgb(236, 232, 251), rgb(253, 233, 233));
  text-shadow: none;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: fadeBackground 7s 1;
}

.header-text-wrap {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  inset: 0;
}

.header-text {
  position: relative;
  top: 20%;
}

.title {
  font-size: 28px;
}

@media (min-width: 768px) {
  .h1-wrapper {
    background-image: url("../components/images/P1080174.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
  }
  .header-text-wrap {
    background: rgba(0, 0, 0, 0.55);
    position: absolute;
    inset: 0;
    border-radius: 0 0 60px 60px;
  }

  .title {
    font-size: 34px;
  }
}

.title span {
  display: inline-block;
  text-align: center;
  -webkit-animation: text-blink-in 3s ease-in-out 1;
  animation: text-blink-in 3s ease-in-out 1;
}

.title span:nth-of-type(1) {
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
}
.title span:nth-of-type(2) {
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}
.title span:nth-of-type(3) {
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}
.title span:nth-of-type(4) {
  -webkit-animation-delay: 0.35s;
  animation-delay: 0.35s;
}
.title span:nth-of-type(5) {
  -webkit-animation-delay: 0.45s;
  animation-delay: 0.45s;
}
.title span:nth-of-type(6) {
  -webkit-animation-delay: 0.55s;
  animation-delay: 0.55s;
}
.title span:nth-of-type(7) {
  -webkit-animation-delay: 0.65s;
  animation-delay: 0.65s;
}
.title span:nth-of-type(8) {
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}
.title span:nth-of-type(9) {
  -webkit-animation-delay: 0.85s;
  animation-delay: 0.85s;
}
.title span:nth-of-type(10) {
  -webkit-animation-delay: 0.95s;
  animation-delay: 0.95s;
}

@media (min-width: 700px) {
  .homepage-info-p:hover {
    background: linear-gradient(90deg, var(--main-blue-bright), var(--main-red-bright));
    text-shadow: none;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
}

.parent-info-link {
  padding: 0px 7px 3px 7px;
}

.parent-info-button {
  font-weight: 600;
  font-style: normal;
  font-size: calc(0.2 * 1vw + 1rem);
  letter-spacing: 0em;
  text-transform: none;
  line-height: 1.6em;
  background: transparent;
  border: solid var(--black) 3px;
  color: var(--black);
  cursor: pointer;
  border-radius: 10px;
  padding: 0.4vw 0.5vw 0.4vw 0.5vw;
  margin-right: 0.4vw;
  margin-left: 0.4vw;
  transition:
    background-color cubic-bezier(0.68, 0.19, 0.7, 0.96) 0.3s,
    color cubic-bezier(0.68, 0.19, 0.7, 0.96) 0.3s,
    padding 200ms,
    margin 200ms;
}

.parent-info-button:hover {
  background-color: white;
  color: var(--main-red);
  border: solid transparent 3px;
  opacity: 0.9;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.22);
}

.parent-info-button:active {
  opacity: 0.6;
}

.homepage-info-p:hover a {
  -webkit-text-fill-color: #0000ee;
}

.homepage-section-containter {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.rectangle-1 {
  position: absolute;
  z-index: -100;
  width: 290px;
  height: 1000px;
  left: 30px;
  top: -310px;

  background: var(--main-red);
  transform: rotate(45deg);
  overflow: hidden;
}

.rectangle-4 {
  position: absolute;
  z-index: -100;
  width: 190px;
  height: 1000px;
  left: 0px;
  top: -300px;

  background: var(--main-red-bright);
  transform: rotate(45deg);
  overflow: hidden;
}

.rectangle-2 {
  position: absolute;
  z-index: -100;
  width: 290px;
  height: 3000px;
  right: 15vw;
  top: -910px;

  background: var(--logo-blue);
  transform: rotate(45deg);
  overflow: hidden;
}

.rectangle-3 {
  position: absolute;
  z-index: -100;
  width: 190px;
  height: 3000px;
  right: 15vw;
  top: -1031px;

  background: var(--main-blue-bright);
  transform: rotate(45deg);
  overflow: hidden;
}

.homepage-info-p {
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0.5rem 0;
  animation: 1s cubic-bezier(1, 1.61, 0.62, 0.02) 0s 1 fadeIn;
  font-weight: 500;
  font-size: 18px;
  max-width: max-content;
  margin-left: auto;
  margin-right: auto;
}

.homepage-info-p2 {
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0.5rem 0;
  animation: 1s cubic-bezier(1, 1.61, 0.62, 0.02) 0s 1 fadeIn;
  font-weight: 500;
  font-size: 18px;
  max-width: max-content;
  margin-left: auto;
  margin-right: auto;
}

.homepage-times {
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 1rem 0;
  animation: 1s cubic-bezier(1, 1.61, 0.62, 0.02) 0s 1 fadeIn;
  font-weight: 500;
  font-size: 18px;
  max-width: max-content;
  margin-left: auto;
  margin-right: auto;
}

.homepage-info-a {
  white-space: nowrap;
  text-decoration: none;
}

.text-red {
  color: var(--main-red-bright);
}

.text-center {
  text-align: center;
}

hr.solid {
  border: 2px solid #bbb;
  border-radius: 8px;
  max-width: 300px;
  margin: auto;
  animation: 1s cubic-bezier(1, 1.61, 0.62, 0.02) 0s 1 fadeIn;
}

.photo-reel-h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main-blue);
  margin: 0 0 -3rem 0;
  font-size: 28px;
}

@-webkit-keyframes wave-text {
  00% {
    transform: translateY(0em);
  }
  60% {
    transform: translateY(-0.22em);
  }
  100% {
    transform: translateY(0em);
  }
}

@keyframes wave-text {
  00% {
    transform: translateY(0em);
  }
  60% {
    transform: translateY(-0.22em);
  }
  100% {
    transform: translateY(0em);
  }
}

@keyframes fadeBackground {
  from {
    background-image: none;
  }
}

@keyframes text-blink-in {
  60% {
    -webkit-text-fill-color: black;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
