.info-page-wrapper {
    width: 100%;
    background: linear-gradient(
        -45deg,
        #fcab14c9,
        #b11a1ac9,
        #004099c9,
        #007430c9
    );
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.info-page-container {
    padding: 1rem;
    padding-top: 0;
}

.info-section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    margin: 1rem;
    border: solid gainsboro 4px;
    border-radius: 8px;
    background-color: rgba(255, 253, 250, 0.7);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    display: inline-block;
}

.info-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 8px;
    white-space: pre-line;
}

.info-block p {
    font-size: 1.1em;
    line-height: calc(1ex / 0.32);
    margin: calc(1ex / 0.32) 0;
}

.info-block h1 {
    margin-bottom: 12px;
    text-transform: uppercase;
}

.section-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
}

.section-image {
    object-fit: fill;
    max-height: 600px;
    max-width: 50vw;
    margin: 1rem;
    border-radius: 8px;
    justify-self: center;
}

@media (max-width: 1099px) {
    .section-image {
        max-width: calc(90vw - 2rem);
    }
}

@media (max-width: 1099px) {
    .info-block {
        flex-direction: column;
        height: auto;
        align-items: center;
    }
}
