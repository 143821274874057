.about-page-wrapper {
    position: relative;
    overflow: hidden;
}

.about-us-container {
    display: inline-block;
    padding: 1rem;
    border: solid gainsboro 4px;
    width: 90%;
    margin: 1rem 5%;
    border-radius: 8px;
    background-color: rgba(255, 253, 250, 0.9);
}

.about-us-container p {
    margin-top: 0.5rem;
    font-weight: 500;
    animation: 0.8s cubic-bezier(1, 1.22, 0.62, 0.02) 0s 1 fadeIn;
}

.about-us-container h2 {
    animation: 0.8s cubic-bezier(1, 1.22, 0.62, 0.02) 0s 1 fadeIn;
}

.bio-container {
    padding: 2rem;
    border: solid gainsboro 4px;
    margin: 1rem 10vw 1rem 8vw;
    border-radius: 8px;
    background-color: rgba(255, 253, 250, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bio-img {
    max-height: 770px;
    max-width: 100%;
    object-fit: cover;
}

.bio-container p {
    margin-top: 0.5rem;
    font-weight: 500;
    animation: 0.8s cubic-bezier(1, 1.22, 0.62, 0.02) 0s 1 fadeIn;
}

.bio-container h1,
.bio-container h3 {
    animation: 0.8s cubic-bezier(1, 1.22, 0.62, 0.02) 0s 1 fadeIn;
}

hr.divider {
    border-top: 3px solid #bbb;
    max-width: 300px;
    margin: 0.5rem 0 0 3rem;
    animation: 1s cubic-bezier(1, 1.61, 0.62, 0.02) 0s 1 fadeIn;
}
