.gallery {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0 12px;
  color: black;
}

.gallery-page-container {
  background-color: white;
}

.pics {
  -webkit-column-break-inside: avoid;
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  margin-bottom: 12px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.pics img {
  box-shadow: 4px 5px 2px rgba(56, 56, 56, 0.589),
    inset -2px -2px 2px rgb(0 0 0 / 25%);
}

.pics:hover {
  opacity: 0.88;
}

@media (min-width: 991px) {
  .gallery {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
}

@media (max-width: 991px) {
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

@media (max-width: 480px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }
}

.gallery-image {
  width: 100%;
}
