.footer-background-overlay {
    width: 100%;
    margin-top: auto;
    padding: 4vw 48px;
    position: relative;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(rgba(24, 43, 73, 0), #182b49);
}

.footer-box {
    max-width: 100vw;
    margin-top: auto;
    padding: 5vh 3vw;
    display: flex;
    position: relative;
    bottom: 0;
    right: 0;
    left: 0;
    line-height: 1;
    justify-content: space-evenly;
    background-color: #fffdfa;
    overflow: hidden;
}

.footer-row {
    width: 100%;
    flex: 1fr;
    display: grid;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .footer-row {
        grid-template-columns: 1fr;
    }
}
@media only screen and (min-width: 769px) and (max-width: 1050px) {
    /* For tablets: */
    .footer-row {
        grid-template-columns: 1fr 1fr;
    }
}

.footer-column {
    display: flex;
    padding-right: 3vw;
    padding-left: 3vw;
    flex-direction: column;
    max-width: 98vw;
}

.footer-column-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

ul {
    margin-block-start: 0;
    padding-inline-start: 0px;
}

.list-item {
    display: flex;
    margin-bottom: 16px;
    list-style-type: none;
    justify-content: left;
}

.footer-title {
    padding-bottom: 40px;
    text-align: start;
}

.footer-link {
    display: flex;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    color: black;
    font-size: 1.1em;
    line-height: 1.5em;
    align-items: center;
    text-align: left;
    letter-spacing: 0px;
    text-decoration: none;
}

.footer-link:hover {
    opacity: 0.77;
}

.icon-footer {
    display: inline-flex;
    flex: 1;
    height: 34px;
    color: black;
    margin-right: 1vw;
    background-color: transparent;
}

.padding-bottom-24 {
    padding-bottom: 24px;
}

.line {
    height: 4px;
    background-color: black;
    margin-bottom: 2vw;
    align-items: flex-start;
    width: 100%;
    border-radius: 3px;
}

.scroll-button {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    color: black;
    font-size: 1.1em;
    line-height: 1.6em;
    text-align: left;
    letter-spacing: 0px;
    text-decoration: none;
    background: none;
    border-radius: 3px;
}

.scroll-button:hover {
    cursor: pointer;
    background-color: rgba(112, 112, 112, 0.2);
}

.scroll-button:active {
    opacity: 0.75;
}
