.card-list {
    display: flex;
    padding: 5.5rem 3rem 3rem 3rem;
    overflow-x: scroll;
}

.card-list::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.card-list::-webkit-scrollbar-thumb {
    background: var(--main-blue);
    border-radius: 10px;
    box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
        inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}

.card-list::-webkit-scrollbar-track {
    background: linear-gradient(
        90deg,
        var(--main-blue),
        var(--main-blue) 1px,
        var(--white) 0,
        var(--white)
    );
}

.card {
    height: 500px;
    width: 380px;
    min-width: 350px;
    padding: 1.5rem;
    border-radius: 16px;
    background: var(--white);
    box-shadow: -1rem 0 3rem #000;
    transition: 0.2s;
    margin: 0;
    scroll-snap-align: start;
    clear: both;
    position: relative;
}

.card-interior {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.card-img-container {
    margin-top: auto;
    margin-bottom: auto;
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
}

.card-image {
    max-height: 350px;
    min-height: 100%;
    object-fit: cover;
    width: 100%;
}

.card a {
    text-decoration: inherit;
    color: inherit;
}

.card:focus-within ~ .card,
.card:hover ~ .card {
    transform: translateX(160px);
}

.card:hover {
    transform: translateY(-1rem);
    -webkit-transform: rotateY(10deg) rotateZ(10deg) translateY(-24px);
}

.card:not(:first-child) {
    margin-left: -130px;
}

.card-header {
    margin-bottom: 20px;
}

.card-header p {
    font-size: 14px;
    margin: 0 0 0.5rem;
    color: #585861;
    cursor: default;
}

.card-header h2 {
    font-size: 20px;
    margin: 0 auto;
    text-decoration: none;
    color: inherit;
    border: 0;
    display: inline-block;
    cursor: pointer;
}

.card-header h2:hover {
    background: linear-gradient(
        90deg,
        var(--main-red-bright),
        var(--main-blue-bright)
    );
    text-shadow: none;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.card-author {
    margin: 3rem 0 0;
    display: grid;
    grid-template-columns: 75px 1fr;
    align-items: center;
    position: relative;
}

.author-avatar {
    grid-area: auto;
    align-self: start;
    position: relative;
    box-sizing: border-box;
}

.author-avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    filter: grayscale(100%);
    display: block;
    overflow: hidden;
    margin: 16px 10px;
}

.author-name {
    grid-area: auto;
    box-sizing: border-box;
}

.author-name-prefix {
    font-style: normal;
    font-weight: 700;
    color: #7a7a8c;
}

.half-circle {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 48px;
    fill: none;
    stroke: #ff8a00;
    stroke-width: 8;
    stroke-linecap: round;
    pointer-events: none;
}

.tags {
    margin: 1rem 0 2rem;
    padding: 0.5rem 0 1rem;
    line-height: 2;
    margin-bottom: 0;
}

.tags a {
    font-style: normal;
    font-weight: 700;
    font-size: 0.5rem;
    color: #7a7a8c;
    text-transform: uppercase;
    font-size: 0.66rem;
    border: 3px solid #28242f;
    border-radius: 2rem;
    padding: 0.2rem 0.85rem 0.25rem;
    position: relative;
}

.tags a:hover {
    background: linear-gradient(90deg, #ff8a00, #e52e71);
    text-shadow: none;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    background-clip: text;
    border-color: white;
}
