.calendar-footer {
	color: gray;
}

.calendar-footer button {
	color: gray;
	border-width: 1px;
	border-radius: 3px;
	margin: 0.1rem;
	padding: 0.1rem;
}

.center {
	text-align: center;
}

.tel {
	color: inherit;
}
