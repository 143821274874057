body {
  margin: 0;
  color: #000;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.3;
}

:root {
  --main-blue: #00496e;
  --main-blue-matte: #002e4e;
  --main-blue-bright: #002e6e;
  --main-red: #851313;
  --main-red-bright: #8e1313;
  --logo-blue: #00497c;
  --logo-red: #cc1010;
  --main-green: #00641c;
  --main-green-bright: #007430;
  --white: rgb(221, 221, 221);
  --black: rgb(12, 12, 12);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  height: 100vh;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

button:hover {
  opacity: 0.9;
}

button:active {
  opacity: 0.8;
}

.center {
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
