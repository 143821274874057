.intro-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: no-repeat center/100%
        url("../images/marquee-get-outside-1.webp");
    background-size: cover;
    color: white;
    height: 30vh;
    padding: 20px;
}

.intro-h2 {
    text-shadow: 2px 0 0 #000, 0 -1px 0 #000, 0 2px 0 #000, -1px 0 0 #000;
    margin: 0;
}

.intro-p {
    text-shadow: 2px 0 0 #000, 0 -1px 0 #000, 0 2px 0 #000, -1px 0 0 #000;
    max-width: 85vw;
}

@media screen and (min-width: 699px) {
    .intro-container {
        align-items: flex-start;
        text-align: left;
        padding: 10%;
    }

    .intro-p {
        max-width: 450px;
    }
}

.info {
    text-align: left;
    padding: 20px;
    background-color: var(--main-green);
    color: white;
}

.info a {
    text-decoration: none;
    color: inherit;
}

.info a:hover {
    opacity: 0.77;
}

.info-h2 {
    text-transform: uppercase;
    font-weight: 400;
    margin: 0;
    font-size: 1.4rem;
}

.info-details-container {
    margin: 40px 0;
}

.info-svgs-container {
    margin: 10px;
    display: flex;
    justify-content: flex-end;
}

.info-detail {
    font-weight: 400;
    font-size: 1rem;
}

.info-icons-container {
    display: flex;
    width: 100%;
}

.info-icon {
    font-size: 2rem;
    margin-right: 20px;
    cursor: pointer;
}

.icon-svg {
    display: inline-flex;
    flex: 1 1;
    height: 41px;
    color: var(--white);
    margin-right: 1vw;
    background-color: transparent;
}

.filter-white {
    filter: invert(1);
}

@media screen and (min-width: 699px) {
    .info {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 20px;
    }

    .info-detail {
        line-height: 2;
    }
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    padding: 20px;
}

.form-h2 {
    margin: 0;
    font-weight: 400;
    text-transform: uppercase;
}

.form-label {
    margin: 10px 0;
    text-align: left;
    width: 100%;
}

.form-input,
.form-textarea {
    font-family: inherit;
    display: block;
    margin-top: 5px;
    font-size: 1rem;
    width: calc(100% - 20px);
    border: 1px solid var(--main-blue);
    border-radius: 2px;
    padding: 10px;
}

.form-textarea {
    min-height: 220px;
}

.form-textarea::placeholder {
    font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, sans-serif;
    font-weight: 400;
}

.form-submit {
    justify-self: end;
    color: white;
    background-color: var(--main-green);
    border-radius: 300px;
    border: none;
    padding: 10px;
    max-height: 70px;
    margin: 0 0.5rem;
}

.form-submit:disabled {
    opacity: 0.7;
}

.form-submit-icon {
    font-size: 3rem;
}

.form-clear {
    justify-self: end;
    font-size: 2.5rem;
    color: white;
    background-color: var(--main-green);
    border-radius: 300px;
    border: none;
    padding: 12px;
    max-height: 70px;
    margin: 00.5rem;
}

.submit-clear-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

@media screen and (min-width: 943px) {
    .form {
        display: grid;
        grid-template-areas:
            "header ."
            "name message"
            "tel message"
            "email message"
            ". submit-clear";
        align-items: start;
        align-content: space-between;
        justify-content: unset;
        gap: 0.5vw 1vw;
        padding: 40px;
    }

    .form-h2 {
        grid-area: header;
        text-align: left;
    }

    .form-label {
        margin: 0;
    }

    #name {
        grid-area: name;
    }
    #email {
        grid-area: email;
    }
    #tel {
        grid-area: tel;
    }

    #message {
        grid-area: message;
        height: 100%;
    }

    .form-textarea {
        height: 200px;
    }

    .submit-clear-wrapper {
        grid-area: submit-clear;
    }
}

.contact-section {
    background-color: white;
    width: 100%;
}

@media screen and (min-width: 799px) {
    .contact-section {
        --offset-height: -60px;

        display: grid;
        grid-template-columns: 3fr 1.5fr;
        width: 80vw;
        height: 475px;
        margin: 0 auto;
        top: var(--offset-height);
        left: 50%;
        margin-left: -40vw;
        margin-bottom: var(--offset-height);
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.15);
    }
}

.form-spinner {
    padding: 1.2rem;
}

.spinner_aj0A {
    transform-origin: center;
    animation: spinner_KYSC 0.75s infinite linear;
    filter: invert(99%) sepia(17%) saturate(281%) hue-rotate(224deg)
        brightness(117%) contrast(100%);
}

@keyframes spinner_KYSC {
    100% {
        transform: rotate(360deg);
    }
}
